import React from 'react'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
import { CortizFitnessPricingMarketing } from '../components/marketing/cortiz-fitness-pricing'

export const FitKidzPricing = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  return (
    <Layout seoTitle="Cortiz Fitness Pricing">
      <animated.div style={springOpacity}>
        <CortizFitnessPricingMarketing />
      </animated.div>
    </Layout>
  )
}

export default FitKidzPricing
