import React from 'react'
import { navigate } from 'gatsby'
import { EndPoint } from '../nav/header-nav'
import { CF_PLAN } from '../payments/cortiz-fitness-payment-form'

export const CortizFitnessBaseDetails = () => (
  <ul>
    <li className="flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        8 sessions a month
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        2 sessions per week
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Nutrition plan
      </p>
    </li>
  </ul>
)

export const CortizFitnessPremiumDetails = () => (
  <ul className="pb-24">
    <li className="flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        12 sessions a month
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        3 sessions per week
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Nutrition plan and macro count
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        At home workout log
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Monthly body / performance analysis
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Save $60 over base plan
      </p>
    </li>
  </ul>
)

export const CortizFitnessPlatinumDetails = () => (
  <ul>
    <li className="flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        16 sessions a month
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        4 sessions per week
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Nutrition plan and macro count
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Healthy and delicious Recipes
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Weekly body / performance analysis
      </p>
    </li>
    <li className="mt-4 flex items-start">
      <div className="flex-shrink-0">
        <svg
          className="h-6 w-6 text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="ml-3 text-base leading-6 font-medium text-gray-500">
        Save $120 over premium plan
      </p>
    </li>
  </ul>
)

export const CortizFitnessPricingMarketing = () => {
  return (
    <div className="bg-primary-800">
      <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          <h2 className="text-lg leading-6 font-semibold text-accent-400 uppercase tracking-wider">
            Pricing
          </h2>
          <p className="mt- text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
            Ready to shape up and accomplish more
          </p>
          <p className="mt-3 max-w-4xl mx-auto text-xl leading-7 text-primary-100 sm:mt-5 sm:text-2xl sm:leading-8">
            Select the plan that works best for you.
          </p>
          <span className="mt-1 max-w-4xl mx-auto text-md leading-4 text-gray-900 sm:mt-5 sm:text-xl sm:leading-8">
            sessions are 45min-55min
          </span>
        </div>
      </div>

      <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-primary-800 lg:h-2/3"></div>
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                  <div className="flex-1 flex flex-col">
                    <div className="bg-white px-6 py-10">
                      <div>
                        <h2 className="text-center text-2xl leading-8 font-medium text-gray-900">
                          Base
                        </h2>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">360</span>
                          </span>
                          <span className="text-xl leading-7 font-medium text-gray-400">
                            /month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <CortizFitnessBaseDetails />
                      <div className="mt-8">
                        <div className="rounded-lg shadow-md">
                          <button
                            onClick={() =>
                              navigate(
                                `${EndPoint.CORTIZ_FITNESS_SIGN_UP}?plan=${CF_PLAN.BASE}`
                              )
                            }
                            className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="relative z-10 rounded-lg shadow-xl">
                  <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-accent-400"></div>
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-primary-500 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                        Most popular
                      </span>
                    </div>
                  </div>
                  <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <div>
                      <h2 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6">
                        Premium
                      </h2>
                      <div className="mt-4 flex items-center justify-center">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                          <span className="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span className="font-extrabold">480</span>
                        </span>
                        <span className="text-2xl leading-8 font-medium text-gray-400">
                          /month
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <CortizFitnessPremiumDetails />
                    <div className="mt-10">
                      <div className="rounded-lg shadow-md">
                        <button
                          onClick={() =>
                            navigate(
                              `${EndPoint.CORTIZ_FITNESS_SIGN_UP}?plan=${CF_PLAN.PREMIUM}`
                            )
                          }
                          className="block w-full text-center rounded-lg border border-transparent bg-primary-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition ease-in-out duration-150"
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                  <div className="flex-1 flex flex-col">
                    <div className="bg-white px-6 py-10">
                      <div>
                        <h2 className="text-center text-2xl leading-8 font-medium text-gray-900">
                          Platinum
                        </h2>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">600</span>
                          </span>
                          <span className="text-xl leading-7 font-medium text-gray-400">
                            /month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <CortizFitnessPlatinumDetails />
                      <div className="mt-8">
                        <div className="rounded-lg shadow-md">
                          <button
                            onClick={() =>
                              navigate(
                                `${EndPoint.CORTIZ_FITNESS_SIGN_UP}?plan=${CF_PLAN.PLATINUM}`
                              )
                            }
                            className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
              <div className="max-w-md mx-auto lg:max-w-5xl">
                <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center shadow">
                  <div className="flex-1">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                        Single session
                      </h3>
                    </div>
                    <div className="mt-4 text-lg leading-7 text-gray-600">
                      Not ready to sign up for a monthly package? No problem,
                      pay for individual sessions until you&apos;re ready to
                      level up.
                    </div>
                  </div>
                  <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <button
                      onClick={() =>
                        navigate(
                          `${EndPoint.CORTIZ_FITNESS_SIGN_UP}?plan=${CF_PLAN.SINGLE_SESSION}`
                        )
                      }
                      className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Purchase single session
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
